* {
  box-sizing: border-box;
}

.progress {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container {
  max-width: 320px;
  margin: auto;
}

.letter {
  font-size: 42px;
  font-family: Arial;
  cursor: default;
}

.letter.vowel {
  cursor: pointer;
  /* font-weight: bold; */
}

.letter.vowel:hover {
  background: red;
}

/* .letter.selected {
  color: blue;
} */

.letter.selected::after,
.letter.vowel:hover::after {
  content: "\0301";
}

.blurred {
  color: transparent;
  text-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
}

.word {
  position: relative;
  text-align: center;
}

.timeout {
  color: initial;
  text-shadow: 0 0 10px white;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  color: red;
  font-size: 20px;
}

.timer {
  text-align: center;
  font-size: 64px;
  font-family: Arial, sans-serif;
  margin-bottom: 20px;
}

.timer.red {
  color: red;
}
.timer.yellow {
  color: orange;
}

.hash {
  color: gray;
}
